import { Store } from "pullstate";
export default new Store({
  fcmToken: "",
  RNfcmToken: "",
  user: {},
  userInfo: null,
  uploadModal: {
    isOpen: false,
    hasCurrentPatient: false
  },
  isAddPatientOpen: false,
  isEditPatientOpen: false,
  isConnectModalityOpen: false,
  isLogoutOpen: false,
  sharingPatient: "",
  isGetFullAccessOpen: true,
  enableViewerAPICall: true,
  enableSidebarAPICall: false,
  patient: {},
  patientDetail: {},
  isDarkMode: null,
  isSettingsModalOpen: false,
  isNotificationSettingsOpen: false,
  isChangeRegionModalOpen: false,
  isReportTemplateOpen: false,
  isFindingsSettingsOpen: false,
  isAutomatedSharingOpen: false,
  isAutomatedConditionOpen: false,
  isUserGroupOpen: false,
  isCreateUserGroup: false,
  isAutomatedFindingsOpen: false,
  isMatchingPatientOpen: false,
  isFormSelectorVisible: false,
  formName: "",
  formId: "",
  isIncomingForm: false,
  isWorkspaceOpen: false,
  emergencyPatientId: null,
  isEmergencyModalOpen: false,
  isConfirmationModalOpen: false,
  userSettings: {},
  appConfig: {},
  downloadExport: false,
  isExportReady: false,
  downloadProgress: 0,
  exportTimestamp: "",
  exportGenFailed: false,
  swiftModeEnabled: false,
  isRNTestBuild: false,
  RNSelectedEnv: "PROD",
  isRNEnvSelectorOpen: false,
  showExportUpdate: false,
  isRNAppStateChange: false,
  RNAppVersion: "",
  newPatientId: "",
  isNotificationSubscribing: false,
  showMobileStudySelector: false,
  isGatewayModalOpen: false,
  hasUpdatedStudy: false
});