import { useStoreState } from "pullstate";
import PropTypes from "prop-types";
import Store from "../../utils/Store";
import Modal from "../common/Modal";
import FINDINGS, { GROUPEDFINDINGS } from "../NotificationSettings/findingsLabels";
import { clone, get, isEmpty, set } from "lodash";
import Checkbox from "../common/Checkbox";
import Toggle from "../common/Toggle";
import Button from "../common/Button";
import { useTranslation } from "react-i18next";

const FindingsModal = ({ allFindings, selectedFindings, activeCategory, onChange }) => {
  const { isVisible } = useStoreState(Store, (s) => ({
    isVisible: s.isAutomatedFindingsOpen,
  }));

  const { t } = useTranslation();

  const onCloseModal = () => {
    Store.update((s) => {
      s.isAutomatedFindingsOpen = false;
    });
  };

  const onChangeMultipleFindings = (value, findings, categoryName) => {
    findings.map((finding) => {
      onChange(categoryName, finding, value);
    });
  };

  return (
    <Modal
      title={
        activeCategory === "forms"
          ? "Forms by Qure.ai"
          : activeCategory === "modality"
          ? "Modalities by Qure.ai"
          : "AI findings by Qure.ai"
      }
      onClose={onCloseModal}
      show={isVisible}
      bgOpacity
      className="flex flex-col w-full sm:w-7/12 h-[88%] lg:w-6/12 xl:w-5/12 sm:!h-auto sm:!max-h-[80%] overflow-hidden"
    >
      <div className="overflow-y-auto scrollbar">
        <div className="p-6">
          {Object.keys(allFindings).map((categoryName) => {
            const findings = allFindings[categoryName];
            const areAllFindingsSelected = isEmpty(findings)
              ? false
              : Object.keys(findings).every(
                  (finding) => selectedFindings && selectedFindings[finding]
                );
            const groupFindings = get(GROUPEDFINDINGS, categoryName, {});
            let renderedFindings = [];

            return (
              <div key={categoryName} className="flex flex-col">
                <div className="flex my-3 justify-between">
                  <div className="font-bold">
                    {get(FINDINGS, [categoryName], categoryName)}
                  </div>
                  <div className="flex items-center">
                    Select All
                    <div className="mx-4">
                      <Toggle
                        value={areAllFindingsSelected}
                        onChange={(value) =>
                          onChangeMultipleFindings(
                            value,
                            Object.keys(findings),
                            categoryName
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                {Object.keys(groupFindings).map((groupFinding) => {
                  const findingsInGroup = groupFindings[groupFinding];
                  const areAllFindingsInGroupSelected = findingsInGroup.every(
                    (finding) => {
                      return selectedFindings && selectedFindings[finding];
                    }
                  );
                  renderedFindings = renderedFindings.concat(findingsInGroup);
                  return (
                    <>
                      <Checkbox
                        key={groupFinding}
                        id={groupFinding}
                        className="w-full my-2 pr-2"
                        checked={areAllFindingsInGroupSelected}
                        onChange={(value) =>
                          onChangeMultipleFindings(value, findingsInGroup, categoryName)
                        }
                        label={get(FINDINGS, [groupFinding], groupFinding)}
                      />
                      <div className="flex flex-wrap px-6">
                        {findingsInGroup.map((finding) => {
                          const isSelected =
                            selectedFindings && selectedFindings[finding];
                          return (
                            <Checkbox
                              key={finding}
                              id={finding}
                              name={"aiFind"}
                              className="w-full sm:w-1/2 my-2 pr-2"
                              checked={isSelected}
                              onChange={(val) => {
                                onChange(categoryName, finding, val);
                              }}
                              label={get(FINDINGS, [finding], finding)}
                            />
                          );
                        })}
                      </div>
                    </>
                  );
                })}
                <div className="flex flex-wrap">
                  {!isEmpty(findings) &&
                    Object.keys(findings)
                      .filter((f) => !renderedFindings.includes(f))
                      .map((finding) => {
                        const isSelected = selectedFindings && selectedFindings[finding];
                        return (
                          <Checkbox
                            key={finding}
                            id={finding}
                            name={"aiFind"}
                            className="w-full sm:w-1/2 my-2 pr-2"
                            checked={isSelected}
                            onChange={(val) => {
                              onChange(categoryName, finding, val);
                            }}
                            label={get(FINDINGS, [finding], finding)}
                          />
                        );
                      })}
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-between px-6 py-4 items-center border-t border-gray-900">
          <div className="text-left">
            <p className="text-gray-100">
              {!isEmpty(selectedFindings)
                ? Object.values(selectedFindings).filter((item) => item).length
                : 0}
            </p>
            <p className="text-md text-gray-300">fields selected</p>
          </div>
          <Button
            isPrimary
            onClick={onCloseModal}
            className={"sm:mt-0 grow-1 ml-4 w-24 sm:w-40"}
          >
            {t("button.done")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

FindingsModal.defaultProps = {
  onChange: () => {},
};

FindingsModal.propTypes = {
  findings: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};

export default FindingsModal;
