import _defineProperty from "/vercel/path1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useQuery } from "react-query";
import servicesUtils from "../utils/servicesUtils";
import Store from "../utils/Store";
import { get } from "lodash";
var useAuthService = function useAuthService(isOpen) {
  return useQuery(["profile", isOpen], function () {
    return servicesUtils.getService("/profile/").then(function (authData) {
      var isLoggedin = get(authData, ["authenticated"], false);
      if (isLoggedin) {
        Store.update(function (store) {
          // eslint-disable-next-line no-param-reassign
          store.user = get(authData, ["details"], {});
          // eslint-disable-next-line no-param-reassign
          store.userInfo = _objectSpread({}, authData);
        });
      }
      return authData;
    });
  }, {
    enabled: isOpen,
    staleTime: 0
  });
};
export default useAuthService;