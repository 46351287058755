import PropTypes from "prop-types";
import Store from "../../utils/Store";
import Modal from "../common/Modal";
import { clone, cloneDeep, find, forEach, get, isEmpty, set } from "lodash";
import { useEffect, useState } from "react";
import Button from "../common/Button";
import FindingsModal from "./FindingsModal";
import classNames from "classnames";
import { findingSections } from ".";
import Toast from "../../components/Toast";
import useAddCondition from "./services/useAddCondition";
import UserIcon from "../common/UserIcon";
import useDeleteCondition from "./services/useDeleteCondition";
import SelectInput from "../FormComponents/SelectInput";
import AwesomeIcon from "../AwesomeIcon";
import { faUsers } from "@fortawesome/pro-solid-svg-icons";
import DeleteModal from "./DeleteModal";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

const ConditionsModal = ({
  allFindings,
  findings,
  isVisible,
  onClose,
  groups: allGroups,
  workspaceOptions,
  currentWorkspaceId,
}) => {
  const { t } = useTranslation();
  const isEdit = !isEmpty(findings);
  const [findingState, setFindingState] = useState({});
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeCategory, setActiveCategory] = useState("");
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(currentWorkspaceId);
  const [search, setSearch] = useState("");
  const [activeTab, setActiveTab] = useState(1);

  const saveCondition = useAddCondition();
  const deleteCondition = useDeleteCondition();

  useEffect(() => {
    if (currentWorkspaceId) {
      setSelectedWorkspaceId(currentWorkspaceId);
    }
  }, [currentWorkspaceId]);

  useEffect(() => {
    if (isVisible) {
      const _findings = cloneDeep(findings);
      const _selectedCategory = find(Object.keys(_findings), (f) => {
        const findingsList = _findings[f];
        return Object.values(findingsList).some((fv) => fv === true);
      });
      setActiveTab(1);
      setFindingState(_findings);
      setActiveCategory(_selectedCategory);
      setSelectedGroups(_findings?.group || []);
    }
  }, [isVisible]);

  const onCloseModal = () => {
    onClose();
    setFindingState({});
    setActiveCategory("");
  };

  const onChangeCategory = (value) => {
    const _allFindings = clone(allFindings);
    const { group } = findingState;
    forEach(Object.keys(findingSections), (key) => {
      const findings = _allFindings[key];
      if (findings) {
        Object.keys(findings).forEach((f) => {
          findings[f] = key === value;
        });
      }
    });

    setFindingState({ ..._allFindings, group });
    setActiveCategory(value);
  };

  const onChangeFindings = (categoryName, finding, val) => {
    const _findingState = clone(findingState);
    set(_findingState, [categoryName, finding], val);
    setFindingState(_findingState);
  };

  const onChangeGroup = (groupId) => {
    let _selectedGroups = clone(selectedGroups) || [];
    if (_selectedGroups.includes(groupId)) {
      _selectedGroups = _selectedGroups.filter((gId) => gId !== groupId);
    } else {
      _selectedGroups.push(groupId);
    }
    setSelectedGroups(_selectedGroups);
  };

  const onSave = async () => {
    const _findingState = clone(findingState);
    if (!isEmpty(_findingState)) {
      _findingState["group"] = selectedGroups?.filter((group) =>
        allGroups.some(({ id }) => group === id)
      );
    }
    if (findings?.id) {
      _findingState["id"] = findings?.id;
    }
    try {
      const params = {
        condition: _findingState,
      };
      await saveCondition.mutateAsync({ params, workspaceId: selectedWorkspaceId });
      setActiveTab(1);
      Toast.success(t("rule.toast.saved_successfully"));
      onCloseModal();
    } catch (err) {
      setActiveTab(1);
      Toast.error(t("rule.toast.saved_failed"));
    }
  };

  const onDelete = async () => {
    setFindingState({});
    try {
      const params = {
        condition: {
          id: findings?.id,
        },
      };
      await deleteCondition.mutateAsync({ params, workspaceId: selectedWorkspaceId });
      Toast.success(t("rule.toast.deleted_successfully"));
      onCloseModal();
      setDeleteModal(false);
      setActiveTab(1);
    } catch (err) {
      setDeleteModal(false);
      setActiveTab(1);
      Toast.error(t("rule.toast.deleted_failed"));
    }
  };

  const filteredGroups = allGroups.filter((g) =>
    g.name.toLowerCase().includes(search.toLowerCase())
  );
  return (
    <>
      <Modal
        title={t(
          isEdit ? "conditional_modal.editing_title" : "conditional_modal.adding_title"
        )}
        onClose={onCloseModal}
        show={isVisible}
        className="flex flex-col w-full sm:w-8/12 xl:w-6/12 h-[90%] sm:h-auto max-h-[90%] overflow-y-auto sm:overflow-hidden"
      >
        <div className="m-4 text-left flex flex-col h-full sm:h-auto gap-y-2 sm:gap-y-0 sm:grid sm:grid-cols-12">
          <div
            className={classNames("col-span-12 sm:col-span-5 space-y-3", {
              block: activeTab === 1,
              "hidden sm:block": activeTab === 2,
            })}
          >
            <div className="space-y-3">
              <div className="text-base hidden sm:block">
                {t("conditional_modal.from_workspace")}
              </div>
              <div className="pb-3 hidden sm:block">
                <SelectInput
                  options={workspaceOptions}
                  value={selectedWorkspaceId}
                  onChange={setSelectedWorkspaceId}
                  disabled={selectedWorkspaceId && workspaceOptions?.length <= 1}
                  className="w-full b-3"
                />
              </div>
              <div className="text-base">{t("conditional_modal.based_on_ai")}</div>
              {!isEmpty(allFindings) &&
                Object.keys(allFindings).map((categoryName) => {
                  const isSelected = categoryName === activeCategory;
                  const findings = get(allFindings, [categoryName], {});
                  const selectedFindings = get(findingState, [categoryName], {});
                  let selectedCount = 0;
                  if (isSelected) {
                    selectedCount = Object.values(selectedFindings).filter(
                      (item) => item
                    ).length;
                  }

                  return (
                    <div key={categoryName} className={"flex space-x-3 items-start py-2"}>
                      <div className="flex flex-col items-center pt-2">
                        <input
                          id={categoryName}
                          name="aiFindings"
                          type="radio"
                          value={categoryName}
                          checked={isSelected}
                          onChange={() => onChangeCategory(categoryName)}
                          className="h-5 w-5 border-gray-600 dark:border-gray-300 text-teal-400 bg-transparent cursor-pointer"
                        />
                      </div>
                      <div className="flex flex-col items-start">
                        <label
                          htmlFor={categoryName}
                          className="text-gray-800 dark:text-gray-200 text-base cursor-pointer"
                        >
                          <p>{t(findingSections[categoryName])}</p>
                        </label>
                        <button
                          className={classNames("text-teal-300", {
                            "cursor-not-allowed text-teal-500": !isSelected,
                            hidden: Object.keys(findings).length <= 1,
                          })}
                          disabled={!isSelected}
                          onClick={() => {
                            Store.update((s) => {
                              s.isAutomatedFindingsOpen = true;
                            });
                          }}
                        >
                          {selectedCount &&
                          selectedCount === Object.values(findings).length
                            ? `All ${
                                categoryName === "forms"
                                  ? "forms"
                                  : categoryName === "modality"
                                  ? "modalities"
                                  : "findings"
                              }`
                            : `${selectedCount} of ${Object.values(findings).length} ${
                                categoryName === "forms"
                                  ? "forms"
                                  : categoryName === "modality"
                                  ? "modalities"
                                  : "findings"
                              }`}
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="hidden sm:block sm:col-span-1 border-r mx-auto border-gray-700"></div>
          <div
            className={classNames("col-span-12 sm:col-span-6 space-y-4", {
              "hidden sm:block": activeTab === 1,
              block: activeTab === 2,
            })}
          >
            {activeTab === 2 && (
              <div className="space-y-3 sm:hidden">
                <div className="text-base">{t("conditional_modal.from_workspace")}</div>
                <div className="pb-3">
                  <SelectInput
                    options={workspaceOptions}
                    value={selectedWorkspaceId}
                    onChange={setSelectedWorkspaceId}
                    disabled={selectedWorkspaceId && workspaceOptions?.length <= 1}
                    className="w-full b-3"
                  />
                </div>
              </div>
            )}
            <p className="text-base">{t("conditional_modal.share_with")}</p>
            <div className="bg-gray-100 dark:bg-gray-900 py-3 rounded-lg">
              <div className="border-b border-gray-800">
                <input
                  type="text"
                  id="search"
                  value={search}
                  placeholder={t("conditional_modal.placeholder")}
                  name="search"
                  onChange={(e) => setSearch(e.target.value)}
                  className="text-md placeholder:text-md bg-transparent placeholder:italic border-none w-full"
                />
              </div>
              <div className="h-80 sm:h-48 overflow-y-auto scrollbar-light dark:scrollbar">
                {filteredGroups?.map((group, index) => {
                  const description = get(group, ["description"], []);
                  const isSelected = selectedGroups && selectedGroups.includes(group?.id);
                  return (
                    <div
                      key={index}
                      className="flex items-center justify-between m-4 border-b border-gray-800 pb-4"
                    >
                      <label
                        htmlFor={group?.id}
                        className="flex space-x-2 items-center cursor-pointer w-full overflow-hidden whitespace-nowrap text-ellipsis"
                      >
                        <div className="flex mr-2">
                          <UserIcon
                            name={group?.name}
                            className="h-7 w-7 text-base text-white p-4 dark:!bg-gray-700"
                          />
                        </div>
                        <div className="flex flex-col w-full">
                          <div className="text-gray-800 dark:text-gray-200 text-md">
                            {group?.name}
                          </div>
                          <div className="text-gray-900 dark:text-gray-400 text-md whitespace-nowrap text-ellipsis overflow-hidden flex">
                            {description}
                          </div>
                        </div>
                      </label>
                      <div className="flex ml-3">
                        <input
                          id={group?.id}
                          aria-describedby="group"
                          name="group"
                          type="checkbox"
                          className="rounded border-gray-600 dark:border-gray-400 cursor-pointer text-teal-400 bg-transparent"
                          checked={isSelected}
                          onChange={(e) => onChangeGroup(group?.id)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex justify-end">
              <Button
                isSecondary
                className="sm:grow-1 sm:w-48 space-x-3 flex bg-gray-800 sm:bg-gray-700"
                onClick={() =>
                  Store.update((s) => {
                    s.isUserGroupOpen = true;
                  })
                }
              >
                <AwesomeIcon
                  name={faUsers}
                  className="block text-teal-300 sm:text-white cursor-pointer"
                />
                <p className="text-teal-300 sm:text-white">
                  {t("conditional_modal.manage_groups")}
                </p>
              </Button>
            </div>
          </div>
          <div className="col-span-12 border-t border-gray-800 w-full mt-4 flex flex-col justify-end flex-1">
            <div className="flex items-center flex-row justify-between p-4 border-t border-t-gray-600">
              <div className="flex items-center justify-center">
                <div
                  // onClick={onDelete}
                  onClick={() => setDeleteModal(true)}
                  className={classNames("flex cursor-pointer", {
                    hidden: !isEdit,
                  })}
                >
                  <AwesomeIcon
                    name={faTrash}
                    className="dark:text-gray-200 text-gray-700"
                  />
                  <span className="pl-2">{t("button.delete")}</span>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <Button
                  isSecondary
                  className="grow-1 w-24 sm:w-40"
                  onClick={() => {
                    if (activeTab === 2) {
                      setActiveTab(1);
                    } else {
                      onCloseModal();
                    }
                  }}
                >
                  {activeTab === 2 ? t("back_key") : t("button.cancel")}
                </Button>
                <Button
                  isPrimary
                  //   isLoading={saveNotificationSettings.isLoading}
                  onClick={() => setActiveTab(2)}
                  className={classNames("sm:mt-0 sm:hidden grow-1 ml-4 w-24 sm:w-40", {
                    hidden: activeTab === 2,
                  })}
                  disabled={isEmpty(activeCategory)}
                >
                  {t("button.next")}
                </Button>
                <Button
                  isPrimary
                  //   isLoading={saveNotificationSettings.isLoading}
                  onClick={onSave}
                  className={classNames("sm:mt-0 grow-1 ml-4 w-24 sm:w-40", {
                    "hidden sm:block": activeTab === 1,
                  })}
                  disabled={isEmpty(activeCategory) || isEmpty(selectedGroups)}
                >
                  {t("button.save")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <FindingsModal
        allFindings={{ [activeCategory]: allFindings[activeCategory] }}
        selectedFindings={findingState[activeCategory]}
        activeCategory={activeCategory}
        onChange={onChangeFindings}
      />
      <DeleteModal
        deleteModal={deleteModal}
        onDelete={onDelete}
        setDeleteModal={setDeleteModal}
        title={t("conditional_modal.delete_title")}
        content={t("conditional_modal.delete_content")}
      />
    </>
  );
};

ConditionsModal.defaultProps = {
  onChange: () => {},
};

ConditionsModal.propTypes = {
  onChange: PropTypes.func,
};

export default ConditionsModal;
